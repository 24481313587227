
.field.eth_documentlist table{
  background: #ffffff;
}

.field.eth_tablelist table{
  width: 100%;
}

.fieldtype-eth_tablelist table th{
  font-weight: normal;
  font-size: 1.05rem;
}

.fieldtype-eth_tablelist table th,.field.eth_tablelist table td{
    padding: 5px;
}


.fieldtype-eth_tablegroup .groupitem label{
  font-size: 1.1em;
}

.fieldtype-eth_tablegroup .groupitem h3{
  font-size: 1.3em;
}

.fieldtype-eth_tablegroup .groupitem .title{
  margin: 5px 0px;
}

.fieldtype-eth_tablegroup table{
  width: 100%;
}

.fieldtype-eth_tablegroup table label{
  margin-right: 5px;
}

.fieldtype-eth_tablegroup table label, .fieldtype-eth_tablegroup table input{
  display: inline;
  width: 50%;
}

.fieldtype-eth_tablegroup textarea{
  width: 100%;
}

.fieldtype-eth_documentlist table th{
  padding: 5px;
  border-bottom: 1px solid #f0f0f0;
}

.fieldtype-eth_documentlist table td:nth-child(1){
  text-align: center;
}

.fieldtype-eth_documentlist .description{
  color: green;
  display: inline-block;
  font-style: italic;
}

.fieldtype-eth_documentlist table td{
    padding: 5px;
}

.field.eth_tablelist .field-body, .field.eth_tablegroup .groupitem, .indicator .indicator-body{
  margin-top: 10px;
  margin-bottom: 40px;
  width: 70%;
  background: #f9f6f0;
  padding: 10px 10px 20px 20px;
  border: 1px solid #cccccc;
  border-radius: 3px;
}

.field-mode-view .field.eth_tablelist .field-body, .field-mode-view .field.eth_tablegroup .groupitem, .field-mode-view .indicator .indicator-body{
  width: 100%;
}

.field > label{
  white-space: pre-wrap;
}